const colors = {
    primary: '#1F7A8C',
    white: '#fff',
    secondary: '#ECEAEA',
    grey: '#706f6f',
    purpal: '#772D8C',
    green: '#1A9968',
    darkGreen: '#236145',
    darkPurpal: '#34143d',
    red: '#d10420',
    black: '#0c0b0d'
};

export default colors;
