// assets
import { IconDashboard } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// constant
const icons = { IconDashboard };
// const { t, i18n } = useTranslation();
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    accessibleRoles: ['1', '2', '3'],
    id: 'dashboard',
    title: 'Dashboard',
    titel_arb: 'لوحة المعلومات',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            titel_arb: 'لوحة المعلومات',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
