/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import cookies from 'js-cookie';
import { useEffect, useState } from 'react';
// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, userRole }) => {
    const theme = useTheme();

    const [pitems, setItems] = useState([]);

    useEffect(() => {
        const titems = item?.children?.filter((menu) => {
            if (menu?.accessibleRoles) {
                return menu?.accessibleRoles?.includes(userRole);
            } else {
                return true;
            }
        });
        setItems(titems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const items = pitems.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;

            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography
                            variant="caption"
                            //change Dashboard Titles text and Color
                            sx={{ ...theme.typography.menuCaption, color: 'white', fontSize: '18px' }}
                            display="block"
                            gutterBottom
                        >
                            {/* {item.title} */}
                            {localStorage.getItem('Nav_Lang') == 'en' ? item.title : item.titel_arb}

                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
