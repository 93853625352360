import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const getAllCampaign = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/campaign-list').then((response) => response);

    return dataPromise;
};

export const addCampaign = (details) => {
    let campaign = {
        campaign_name: details.campaign_name,
        title: details.title,
        content: details.content,
        auto: details.auto ? details.auto : false,
        sms: details.sms ? details.sms : false,
        push_notification: details.push_notification ? details.push_notification : false,
        created_by: details.created_by,
        created_at: '2023.01.01',
        enable: details.enable ? details.enable : false,
        monthly: details.monthly ? details.monthly : false,
        yearly: details.yearly ? details.yearly : false,
        weekly: details.weekly ? details.weekly : false,
        type: details.type,
        country_id: details.country_id,
        company_id: details.company_id
    };
    
    const dataPromise = commonApiTemplateForPost(null, campaign, 'v1/campaign').then((response) => response);

    return dataPromise;
};

export const UpdateCampaign = (details) => {
    let campaign = {
        campaign_name: details.campaign_name,
        title: details.title,
        content: details.content,
        auto: details.auto ? details.auto : false,
        sms: details.sms ? details.sms : false,
        push_notification: details.push_notification ? details.push_notification : false,
        created_by: '',
        created_at: '2023.01.01',
        enable: details.enable ? details.enable : false,
        monthly: details.monthly ? details.monthly : false,
        yearly: details.yearly ? details.yearly : false,
        weekly: details.weekly ? details.weekly : false,
        type: details.type,
        country_id: details.country_id,
        company_id: details.company_id,

        id: details.id
    };

    const dataPromise = commonApiTemplateForPut(null, campaign, 'v1/campaign').then((response) => response);

    return dataPromise;
};

export const getCampaignById = (id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/get-campaign-by-id').then((response) => response);
    return dataPromise;
};

export const getCampaignHistoryListById = (campaign_id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('campaign_id', campaign_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/campaign-history-list-by-campaign-id').then(
        (response) => response
    );
    return dataPromise;
};

export const CampaignFilter = (filterCompany, filterCuntry, filterType) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_id', filterCompany);
    bodyFormData.append('country_id', filterCuntry);
    bodyFormData.append('type', filterType);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/campaign-filter').then((response) => response);

    return dataPromise;
};

export const ResendSMS = (campaignId, senderId) => {
    var bodyFormData = new FormData();

    bodyFormData.append('campaign_id', campaignId);
    bodyFormData.append('sednder_id', senderId);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/campaign-resend').then((response) => response);
    return dataPromise;
};

export const CampaignCount = (campaignId) => {
    var bodyFormData = new FormData();

    bodyFormData.append('campaign_id', campaignId);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/history_count_campaign').then((response) => response);
    return dataPromise;
};
