// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const CustomerPage = {
    id: 'CustomerPage',
    title: 'CustomerPage',
    // title_arb:'',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'Customers',
            title: 'Customers',
            title_arb: 'عملاء',
            type: 'collapse',
            icon: icons.IconKey,

            children: [
                {
                    id: 'customersList',
                    title: 'CustomersList',
                    title_arb: 'قائمة العملاء',
                    type: 'item',
                    url: '/views/customer/customersList',
                    breadcrumbs: false
                },
                {
                    id: 'customersGroup',
                    title: 'CustomersGroup',
                    // title_arb:'',
                    type: 'item',
                    url: '/views/customer/Customer_Satisfaction',
                    breadcrumbs: false
                },
                {
                    id: 'SignInReports',
                    title: 'Sign In Reports',
                    title_arb: 'تقارير تسجيل الدخول ',
                    type: 'item',
                    url: '/views/customer/signinreports',
                    breadcrumbs: false
                },
                {
                    id: 'SignUpReports',
                    title: 'Sign Up Reports',
                    title_arb: 'تقارير التسجيل',
                    type: 'item',
                    url: '/views/customer/signupreports',
                    breadcrumbs: false
                },
                {
                    id: 'RateReview',
                    title: 'Rate Review',
                    type: 'item',
                    url: '/views/customer/ratereview',
                    breadcrumbs: false
                },
                {
                    id: 'ReviewList',
                    title: 'ReviewList',
                    type: 'item',
                    url: '/views/customer/reviewlist',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default CustomerPage;
