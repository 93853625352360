/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import Lazy from 'yup/lib/Lazy';
import UnregisterdCustomers from 'views/Customer/UnregisterdCustomers';

//customer option routing
const CustomersList = Loadable(lazy(() => import('views/Customer/CustomersList')));
const Customersatisfaction = Loadable(lazy(() => import('views/Customer/Customersatisfaction')));
const SignInReports = Loadable(lazy(() => import('views/Customer/SignInReports')));
const SignUpReports = Loadable(lazy(() => import('views/Customer/SignUpReports')));
const RateReview = Loadable(lazy(() => import('views/Customer/RateReview')));
const ReviewList = Loadable(lazy(() => import('views/Customer/ReviewList')));
const GiftCardReport = Loadable(lazy(() => import('views/Customer/GiftCardReport')));
const CoupanReport = Loadable(lazy(() => import('views/Customer/CoupanReport')));
const CampaignList = Loadable(lazy(() => import('views/SmsCampaign/CampaignList')));
// ==============================|| CUSTOMER ROUTING ||============================== //

const CustomerRouting = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/views/customer/customersList',
            element: <CustomersList />
        },
        {
            path: '/views/customer/Customer_Satisfaction',
            element: <Customersatisfaction />
        },
        {
            path: '/views/customer/signinreports',
            element: <SignInReports />
        },
        {
            path: '/views/customer/signupreports',
            element: <SignUpReports />
        },
        {
            path: '/views/customer/ratereview',
            element: <RateReview />
        },
        {
            path: '/views/customer/reviewlist',
            element: <ReviewList />
        },
        {
            path: 'views/Customer/GiftCardReport',
            element: <GiftCardReport />
        },
        {
            path: 'views/Customer/CoupanReport',
            element: <CoupanReport />
        },
        {
            path: '/smsCampaign/CampaignList',
            element: <CampaignList />
        },
        {
            path: '/views/customer/unregiserdCustomers',
            element: <UnregisterdCustomers />
        }
    ]
};

export default CustomerRouting;
