/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authActions } from 'store/authSlice';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography,
    Tooltip,
    Fab,
    IconButton
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser, IconWorld, IconLanguage } from '@tabler/icons';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| PROFILE MENU ||============================== //

const LangugeChange = () => {
    const theme = useTheme();

    const customization = useSelector((state) => state.customization);
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 4) {
            changeLanguageHandler('arb');
        } else {
            changeLanguageHandler('en');
        }
        handleClose(event);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    //Calling t and i18n method from useTranslation hook
    const { t, i18n } = useTranslation();

    const changeLanguageHandler = (name) => {
        // const languageValue = name;
        localStorage.setItem('Nav_Lang', name);
        i18n.changeLanguage(name);
        document.body.dir = localStorage.getItem('Nav_Lang') == 'en' ? 'ltr' : 'rtl';
        navigate(window.location.pathname);
        //  dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
        // window.location.reload();
    };

    useEffect(() => {
        const name = localStorage.getItem('Nav_Lang');
        i18n.changeLanguage(name);
        document.body.dir = localStorage.getItem('Nav_Lang') == 'en' ? 'ltr' : 'rtl';
        navigate(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    mr: 5,
                    ml: 5,
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    borderColor: theme.palette.primary.main,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        backgroundColor: theme.palette.primary.light
                        // background: `${theme.palette.primary.main}!important`
                        // color: theme.palette.primary.light,
                        // '& svg': {
                        //     stroke: theme.palette.primary.light
                        // }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                label={<IconWorld stroke={1.5} size="1.5rem" color={theme.palette.grey[900]} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 1 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 100,
                                                    minWidth: 80,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={setSelectedIndex(0)}
                                                    onClick={(event) => handleListItemClick(event, 0)}
                                                >
                                                    <ListItemText primary={<Typography variant="h5">{t('ENGLISH')}</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={setSelectedIndex(4)}
                                                    onClick={(event) => handleListItemClick(event, 4)}
                                                >
                                                    <ListItemText primary={<Typography variant="h5">{t('ARABIC')}</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default LangugeChange;
