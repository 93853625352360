/* eslint-disable no-unused-vars */
import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import axios from 'axios';
import i18n from 'i18next';
import { useTranslation, initReactI18next, I18nextProvider } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

//axios.defaults.baseURL = 'http://52.220.0.74:8082/api/';
// axios.defaults.baseURL = 'http://54.254.82.30:8082/api/'; live without domain
axios.defaults.baseURL = 'https://imtinan.net:8082/api/';
let persistor = persistStore(store);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

serviceWorker.unregister();
