/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Grid, Modal, Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
// import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import popupStyles from '../../components/popup';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const DialogBoxPopup = ({ title, width, show, onClose, message, type }) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) setOpen(show);

        return () => {
            unmounted = true;
        };
    }, [show]);

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle variant="h3" id="alert-dialog-title" color={type == 'error' ? 'red' : 'black'}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t('OK')}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogBoxPopup;
