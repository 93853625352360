/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SmsCampaign from 'views/SmsCampaign';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/PrivacyPolicy')));
const RolesPrivileges = Loadable(lazy(() => import('views/Roles-Privileges')));
const BranchManagement = Loadable(lazy(() => import('views/Branch-Management')));
const CompanyManagement = Loadable(lazy(() => import('views/Company-Management')));
const System = Loadable(lazy(() => import('views/System')));
const CustomerGroupCreation = Loadable(lazy(() => import('views/Customer/CustomerGroupCreation')));
const CreateRewards = Loadable(lazy(() => import('views/Rewards/CreateRewards')));
const ViewBranch = Loadable(lazy(() => import('views/Branch-Management/ViewBranch')));
const CountryManagement = Loadable(lazy(() => import('views/Country-Management')));
const CreateNewCountry = Loadable(lazy(() => import('views/Country-Management/CreateNewCountry')));
const BannerDefinition = Loadable(lazy(() => import('views/System/BannerDefinition')));
const AppContent = Loadable(lazy(() => import('views/System/AppContent')));
const SystemOffer = Loadable(lazy(() => import('views/System/SystemOffer')));
const OtherSystemConfigaration = Loadable(lazy(() => import('views/System/OtherSystemConfigaration')));
const EmployeeList = Loadable(lazy(() => import('views/EmployeeList')));
const CustomerFeedBack = Loadable(lazy(() => import('views/CustomerFeedBack')));
const ReviewList = Loadable(lazy(() => import('views/Customer/ReviewList')));
const PushNotification = Loadable(lazy(() => import('views/PushNotification')));
const ReferralCode = Loadable(lazy(() => import('views/ReferralCode')));
const Content = Loadable(lazy(() => import('views/System/Content')));
const Sms = Loadable(lazy(() => import('views/System/Sms')));
const CampaignList = Loadable(lazy(() => import('views/SmsCampaign/CampaignList')));
const PrivacyPolicy = Loadable(lazy(() => import('views/PrivacyPolicy')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'privacy-policy',
            element: <SamplePage />
        },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        {
            path: 'Roles-Privileges',
            element: <RolesPrivileges />
        },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        {
            path: 'EmployeeList',
            element: <EmployeeList />
        },
        {
            path: 'PushNotification',
            element: <PushNotification />
        },
        {
            path: 'PrivacyPolicy',
            element: <PrivacyPolicy />
        },

        {
            path: 'ReferralCode',
            element: <ReferralCode />
        },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        {
            path: 'Branch-Management',
            element: <BranchManagement />
        },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        {
            path: 'Company-Management',
            element: <CompanyManagement />
        },
        {
            path: 'System',
            element: <System />
        },
        {
            path: '/views/System/BannerDefinition',
            element: <BannerDefinition />
        },
        {
            path: '/views/customer/CustomerGroupCreation',
            element: <CustomerGroupCreation />
        },
        {
            path: '/views/Rewards/CreateRewards',
            element: <CreateRewards />
        },
        {
            path: '/views/BranchManagement/ViewBranch',
            element: <ViewBranch />
        },
        {
            path: 'Country-Management',
            element: <CountryManagement />
        },
        {
            path: '/views/Country-Management/CreateNewCountry',
            element: <CreateNewCountry />
        },
        {
            path: '/views/System/AppContent',
            element: <AppContent />
        },
        {
            path: '/views/System/Systemoffer',
            element: <SystemOffer />
        },
        {
            path: '/views/System/Othersystemconfigaration',
            element: <OtherSystemConfigaration />
        },
        {
            path: '/views/System/sms',
            element: <Sms />
        },
        {
            path: 'CustomerFeedBack',
            element: <CustomerFeedBack />
        },
        {
            path: 'ReviewList',
            element: <ReviewList />
        },
        {
            path: '/views/System/content',
            element: <Content />
        },
        {
            path: 'SmsCampaign',
            element: <SmsCampaign />
        },
        {
            path: 'CampaignList',
            element: <CampaignList />
        }
    ]
};

export default MainRoutes;
