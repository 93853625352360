// assets
import {
    IconBrandChrome,
    IconHelp,
    IconShieldLock,
    IconTrophy,
    IconBuildingStore,
    IconStar,
    IconUsers,
    IconSettings,
    IconFile,
    IconFlag,
    IconUserPlus,
    IconMessageReport,
    IconBellRinging,
    IconReportSearch,
    IconDeviceMobileMessage,
    IconAward,
    IconAd
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconShieldLock,
    IconTrophy,
    IconBuildingStore,
    IconStar,
    IconUsers,
    IconSettings,
    IconFile,
    IconFlag,
    IconUserPlus,
    IconMessageReport,
    IconBellRinging,
    IconReportSearch,
    IconDeviceMobileMessage,
    IconAward,
    IconAd
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Menu = [
    {
        accessibleRoles: ['1'],
        id: 'Country-Management',
        title: 'Country Management',
        titel_arb: 'إدارة الدول',
        type: 'item',
        url: '/country-management',
        icon: icons.IconFlag,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'Company-Management',
        title: 'Company Management',
        titel_arb: 'إدارة الشركات',
        type: 'item',
        url: '/company-management',
        icon: icons.IconStar,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'Branch-Management',
        title: 'Branch Management',
        titel_arb: 'إدارة الفروع',
        type: 'item',
        url: '/branch-management',
        icon: icons.IconBuildingStore,
        breadcrumbs: false
    },

    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'Customers',
        title: 'Customers',
        titel_arb: 'عملاء',
        type: 'collapse',
        icon: icons.IconUsers,
        children: [
            {
                id: 'customersList',
                title: 'Signed-up Customers List',
                titel_arb: 'قائمة العملاء',
                type: 'item',
                url: '/views/customer/customersList',
                breadcrumbs: false
            },
            {
                id: 'customersunregidter',
                title: 'Unregistered Customers',
                titel_arb: 'العملاء غير المسجلين',
                type: 'item',
                url: '/views/customer/unregiserdCustomers',
                breadcrumbs: false
            }

            // {
            //     id: 'customersReport',
            //     title: 'CustomersReport',
            //     type: 'item',
            //     url: '/views/customer/customersReport',
            //     breadcrumbs: false
            // }
        ]
    },

    {
        accessibleRoles: ['1'],
        id: 'Roles-Privileges',
        title: 'Roles & Privileges',
        titel_arb: 'الأدوار والصلاحيات',
        type: 'item',
        url: '/roles-privileges',
        icon: icons.IconShieldLock,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'EmployeeList',
        title: 'Employee List',
        titel_arb: 'قائمة موظف',
        type: 'item',
        url: '/employeelist',
        icon: icons.IconUserPlus,
        breadcrumbs: false
    },

    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'System',
        title: 'System',
        titel_arb: 'نظام',
        type: 'collapse',
        icon: icons.IconSettings,
        children: [
            {
                id: 'System-Configuration',
                title: 'System Configuration',
                titel_arb: 'أعدادات النظام',
                type: 'item',
                url: '/system',
                breadcrumbs: false
            },
            {
                id: 'BannerDefinition',
                title: 'Banner Definition',
                titel_arb: 'تعريف البانر',
                type: 'item',
                url: '/views/system/bannerdefinition',
                breadcrumbs: false
            },

            {
                id: 'OtherSystemConfigaration',
                title: 'Other Configaration',
                titel_arb: 'تكوينات أخرى',
                type: 'item',
                url: '/views/system/othersystemconfigaration',
                breadcrumbs: false
            }
        ]
    },

    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'Content',
        title: 'Content',
        titel_arb: 'الأحكام والشروط',
        type: 'collapse',
        icon: icons.IconReportSearch,
        children: [
            {
                id: 'Content',
                title: 'Create Titles',
                titel_arb: 'إنشاء عناوين',
                type: 'item',
                url: '/views/system/content',
                breadcrumbs: false
            },

            {
                id: 'AppContent',
                title: 'System Content',
                titel_arb: 'الأحكام والشروط',
                type: 'item',
                url: '/views/system/appcontent',
                breadcrumbs: false
            },
            {
                id: 'SystemOffer',
                title: 'Customer Offer',
                titel_arb: 'عرض النظام',
                type: 'item',
                url: '/views/system/systemoffer',
                breadcrumbs: false
            }
        ]
    },

    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'CustomerFeedBack',
        title: 'Customer Feedback',
        titel_arb: 'ملاحظات العملاء',
        type: 'item',
        url: '/customerfeedback',
        icon: icons.IconMessageReport,
        breadcrumbs: false
    },
    {
        id: 'sms',
        title: 'SMS',
        titel_arb: 'رسالة قصيرة',
        type: 'item',
        url: '/views/system/sms',
        icon: icons.IconDeviceMobileMessage,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'PushNotification',
        title: 'Push Notification',
        titel_arb: 'ارسل الإخطارات',
        type: 'item',
        url: '/pushnotification',
        icon: icons.IconBellRinging,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'ReferralCode',
        title: 'Referral Code',
        titel_arb: 'كود الإحالة',
        type: 'item',
        url: '/referralCode',
        icon: icons.IconAward,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'SmsCampaign',
        title: 'Campaign',
        titel_arb: 'حملة الرسائل القصيرة',
        type: 'item',
        url: '/smsCampaign',
        icon: icons.IconAd,
        breadcrumbs: false
    },
    {
        accessibleRoles: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        id: 'CustomersReport',
        title: 'Customer Reports',
        titel_arb: 'تقارير العملاء',
        type: 'collapse',
        icon: icons.IconFile,
        children: [
            {
                id: 'SignInReports',
                title: 'Sign In Reports',
                titel_arb: 'تقارير تسجيل الدخول ',
                type: 'item',
                url: '/views/customer/signinreports',
                breadcrumbs: false
            },
            {
                id: 'SignUpReports',
                title: 'Sign Up Reports',
                titel_arb: 'تقارير التسجيل',
                type: 'item',
                url: '/views/customer/signupreports',
                breadcrumbs: false
            },
            {
                id: 'Customersatisfaction',
                title: 'Feedback Report',
                titel_arb: 'تقرير التعليقات',
                type: 'item',
                url: '/views/customer/customer_satisfaction',
                breadcrumbs: false
            },
            {
                id: 'RateReview',
                title: 'Rate & Review',
                titel_arb: 'تقارير تسجيل الدخول ',
                type: 'item',
                url: '/views/customer/ratereview',
                breadcrumbs: false
            },
            {
                id: 'GiftCardReport',
                title: 'Gift Card Report',
                titel_arb: 'تقرير بطاقة الهدايا',
                type: 'item',
                url: '/views/customer/giftcardreport',
                breadcrumbs: false
            },
            {
                id: 'CoupanReport',
                title: 'Coupon Report',
                titel_arb: 'تقرير القسيمة',
                type: 'item',
                url: '/views/customer/coupanreport',
                breadcrumbs: false
            }
            // {
            //     id: 'customersReport',
            //     title: 'CustomersReport',
            //     type: 'item',
            //     url: '/views/customer/customersReport',
            //     breadcrumbs: false
            // }
        ]
    },

    {
        accessibleRoles: ['1'],
        id: 'privacy-policy',
        title: 'Privacy Policy',
        titel_arb: 'سياسة الخصوصية',
        type: 'item',
        url: '/privacy-policy',
        icon: icons.IconHelp,
        breadcrumbs: false
    }
];

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: Menu
};

export default other;
