// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import createUser from 'services/auth-service.js/authService';

// export const aqquireUserRole = createAsyncThunk('auth/getUserRole', async (payload) => {
//     const response = await createUser(payload);
//     return response.json();
// });

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        token: null,
        currentUser: null,
        user: null
    },
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            state.currentUser = action.payload;
            state.token = action.payload.token;
        },
        logout: (state, action) => {
            state.isLoggedOut = false;
            state.token = null;
            state.currentUser = null;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setRole: (state, action) => {
            state.user = action.payload;
        }
    }
});

export const { setUser, login } = authSlice.actions;

export const authActions = authSlice.actions;

// export const aqquireUserRole = (payload) => {
//     return async (dispatch) => {
//         try {
//             const response = await createUser(payload);
//             const data = await response.json();
//             dispatch(setUser(data));
//         } catch (e) {}
//     };
// };

export default authSlice;
