/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Box, Stack, Grid, TextField, Button, MenuItem, Typography, CircularProgress, Chip, Switch } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SearchSection from 'layout/MainLayout/Header/SearchSection';
import AddButton from 'layout/Buttons/AddButtons';
import ReusableTable from 'layout/Table/ReusableTable';
import Popup from '../../layout/Popup';
import {
    addBranch,
    getAllBranches,
    getBrancheById,
    upadtedBranch,
    getBranchesByName,
    BranchFilter
} from 'services/branch-service/branchService';
import TableAction from 'layout/Buttons/TableActionButton/TableAction';
import { getAllCountries } from 'services/country-service/countryService';
import { CompanyFiltering, getAllCompanies } from 'services/company-service/companyService';
import DialogBoxPopup from 'layout/DialogBox';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import { CSVLink } from 'react-csv';
import CSVButtons from 'layout/Buttons/CSVButtons';
import {
    CampaignFilter,
    ResendSMS,
    UpdateCampaign,
    addCampaign,
    getAllCampaign,
    getCampaignById
} from 'services/campaign-service/campaign-service';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
// ==============================|| Branch Page ||============================== //

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const SmsCampaign = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [tableRows, setTableRows] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [branches, setBranches] = useState([]);
    const [errors, setErrors] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [showUpadatePopup, setShowUpadatePopup] = useState(false);
    const [showSendPopup, setShowSendPopup] = useState(false);
    const [isSubmitUpadateForm, setIsSubmitUpadateForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showResendPopup, setShowResendPopup] = useState(false);
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [isSubmitForm, setIsSubmitForm] = useState(false);
    const [isSubmitSendForm, setIsSubmitSendForm] = useState(false);
    const [CampaignData, setCampignData] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    });
    const [sms, setSMS] = useState([]);
    const [alertPopup, setAlertPopup] = useState({
        action: false,
        message: null,
        type: null,
        title: null
    });

    const [privilages, setPrivilages] = useState({});
    const [viewEnable, SetViewEnable] = useState(false);
    const [acceseUpdated, setAcceseUpdated] = useState(false);
    const navigate = useNavigate();

    // const useSele

    const [navigatePopup, setnavigatePopup] = useState({
        action: false,
        message: null,
        type: null,
        title: null
    });

    const handlenavigatePopupClose = () => {
        navigate('/dashboard');
    };

    // // table columns
    const tableColumns = [
        {
            id: 'created_by',
            label: `${t('U.ID')}`,
            align: 'left'
        },
        {
            id: 'created_at',
            label: `${t('DATE')}`,
            align: 'left'
        },
        {
            id: 'campaign_name',
            label: `${t('CAMPAIGN')}`,
            align: 'left'
        },
        {
            id: 'enable',
            label: `${t('STATUS')}`,
            align: 'left'
        },

        {
            id: 'push_notification',
            label: `${t('P.NOTIFICATION')}`,
            align: 'left'
        },
        {
            id: 'sms',
            label: `${t('SMS')}`,
            align: 'left'
        },
        // {
        //     id: 'weekly',
        //     label: 'Weekly',
        //     align: 'left'
        // },
        // {
        //     id: 'monthly',
        //     label: 'Monthly',
        //     align: 'left'
        // },

        // {
        //     id: 'yearly',
        //     label: 'Yearly',
        //     align: 'left'
        // },
        {
            id: '_auto',
            label: `${t('AUTO')}`,
            align: 'left'
        },
        {
            id: 'resendButton',
            label: `${t('SEND')}`,
            align: 'center'
        },

        {
            id: 'action1',
            label: `${t('UPDATE')}`,
            align: 'right'
        },
        {
            id: 'action2',
            label: `${t('VIEW')}`,
            align: 'right'
        }
    ];

    const headers = [
        { key: 'created_by', label: `${t('U.ID')}` },
        { key: 'created_at', label: `${t('DATE')}` },
        { key: 'campaign_name', label: `${t('CAMPAIGN')}` },
        { key: 'content', label: `${t('CONTENT')}` }
    ];

    const [filterCountry, setFilterCountry] = useState('all');
    const [filterCompany, setFilterCompany] = useState('all');
    const [filterType, setFilterType] = useState('all');
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    const authState = useSelector((state) => state.auth);
    const [sender, setSender] = useState('');
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setRefresh(!refresh);
        setErrors({});

        setErrors(validateForm(inputs));
        setIsSubmitForm(true);
    };

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page: page });
    };

    const handleLimitChange = (limit) => {
        setPagination({ ...pagination, limit: limit });
    };

    const handlePopupClose = () => setShowPopup(false);
    const handleResendPopupClose = () => setShowResendPopup(false);

    useEffect(() => {
        getAllCountries().then((response) => {
            setCountries(response);
        });
        getAllCompanies().then((response) => {
            setCompanies(response);
        });
    }, []);

    const handleNavigateView = (id) => {
        localStorage.setItem('id', id);
        navigate('/smsCampaign/CampaignList');
    };

    console.log('inputs', inputs);
    console.log('CampaignData', CampaignData);
    useEffect(() => {
        const privilageArray = secureLocalStorage.getItem('privi');
        if (privilageArray) {
            privilageArray.forEach((data) => {
                if (data.feature_name === '') {
                    setAcceseUpdated(true);
                    setPrivilages(data);
                    SetViewEnable(data.is_true);
                }
            });
        }
    }, []);

    const handleClick = (id) => {
        setSender(id);
        setShowResendPopup(true);
    };

    const handleEdit = (id) => {
        getCampaignById(id).then((res) => {
            setShowUpadatePopup(true);

            setInputs({
                id: res.id,
                campaign_name: res.campaign_name,
                content: res.content,
                created_by: res.created_by,
                auto: res.auto,
                enable: res.enable,
                push_notification: res.push_notification,
                sms: res.sms,
                company_id: res.company_id,
                country_id: res.country_id,
                type: res.type,
                title: res.title
            });
        });
    };

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitUpadateForm) {
            setIsSubmitUpadateForm(false);
            setLoading(true);
            setRefresh(!refresh);
            UpdateCampaign(inputs)
                .then(() => {
                    setLoading(false);
                    setUpdated(!updated);
                    handleUpdatePopupClose();
                    setAlertPopup({
                        action: true,
                        message: t('YOU HAVE SUCCESSFULLY UPDATE A CAMPAIGN'),
                        type: 'success',
                        title: t('UPDATED A  CAMPAIGN')
                    });
                })
                .catch((error) => {
                    handleUpdatePopupClose();
                    setLoading(false);
                    setAlertPopup({
                        action: true,
                        message: t('OOPS! SOMTHING WENT WRONG...PLEASE TRY AGAIN'),
                        type: 'error',
                        title: t('ERROR')
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        setInputs({
            ...inputs,
            created_by: authState.currentUser.newUserid,
            created_at: ''
        });

        if (Object.keys(errors).length === 0 && isSubmitForm) {
            setIsSubmitForm(false);
            setLoading(true);
            addCampaign(inputs)
                .then(() => {
                    setRefresh((prevRefresh) => !prevRefresh); // Toggle refresh
                    handlePopupClose();
                    setUpdated(!updated);
                    setLoading(false);
                    setAlertPopup({
                        action: true,
                        message: t('YOU HAVE SUCCESSFULLY ADDED A NEW CAMPAIGN'),
                        type: 'success',
                        title: t('A NEW BRANCH ADDED')
                    });
                })
                .catch((error) => {
                    handlePopupClose();
                    setLoading(false);
                    setAlertPopup({
                        action: true,
                        message: t('OOPS! SOMETHING WENT WRONG... PLEASE TRY AGAIN'),
                        type: 'error',
                        title: t('ERROR')
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, refresh]);

    useEffect(() => {
        if (isSubmitSendForm) {
            setIsSubmitSendForm(false);
            setLoading(true);
            ResendSMS(sender, authState.currentUser.newUserid)
                .then(() => {
                    handleResendPopupClose();
                    setAlertPopup({
                        action: true,
                        message: t('YOU HAVE SUCCESFULLY SEND Message'),
                        type: 'success',
                        title: t('A NEW NOTIFICATION SENT')
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    handleResendPopupClose();
                    setLoading(false);
                    setAlertPopup({
                        action: true,
                        message: t('OOPS! SOMTHING WENT WRONG... PLEASE TRY AGAIN'),
                        type: 'error',
                        title: t('ERROR')
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSendForm]);

    const handleUpdatePopupClose = () => {
        setShowUpadatePopup(false);
        setErrors({});
    };

    const handleSendPopupClose = () => {
        setShowSendPopup(false);
        setErrors({});
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();

        setErrors(validateForm(inputs));

        setIsSubmitUpadateForm(true);
    };

    const handleResendSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitSendForm(true);
    };

    const validateForm = (values) => {
        const formErrors = {};
        if (!values.campaign_name) {
            formErrors.campaign_name = t('BRANCH NAME IS REQUIRED');
        }
        if (!values.title) {
            formErrors.title = t('COMPANY IS REQUIRED');
        }
        if (!values.content) {
            formErrors.content = t('COMPANY IS REQUIRED');
        }

        return formErrors;
    };

    const handleTypeSelection = (e) => {
        const value = e.target.value;
        let typeValue = '';
        if (value === 'birthday') {
            typeValue = 'Birthday';
        } else if (value === 'inactive') {
            typeValue = 'Inactive';
        }
        setInputs({
            ...inputs,
            type: typeValue
        });
    };

    //Campaign list
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const result = await CampaignFilter(
    //                 filterCountry === 'all' ? 0 : filterCountry,
    //                 filterCompany === 'all' ? 0 : filterCompany,
    //                 filterType === 'all' ? 'all' : filterType
    //             );

    //             let tableDataArr = [];

    //             result.map((data) => {
    //                 const isEnabled = data.enable === true;
    //                 const isPushNotification = data.push_notification === true;
    //                 const isSMS = data.sms === true;
    //                 const auto = data.auto === true;

    //                 tableDataArr.push({
    //                     campaign_name: data.campaign_name || '-',
    //                     content: data.content || '-',
    //                     created_by: data.created_by || '-',
    //                     enable: isEnabled ? (
    //                         <Typography>
    //                             <Chip label="Enable" color="primary" size="small" />
    //                         </Typography>
    //                     ) : (
    //                         <Typography>
    //                             <Chip label="Disable" color="primary" size="small" variant="outlined" />
    //                         </Typography>
    //                     ),
    //                     push_notification: isPushNotification ? (
    //                         <Typography>
    //                             <Chip label="Enable" color="primary" size="small" />
    //                         </Typography>
    //                     ) : (
    //                         <Typography>
    //                             <Chip label="Disable" color="primary" size="small" variant="outlined" />
    //                         </Typography>
    //                     ),
    //                     sms: isSMS ? (
    //                         <Typography>
    //                             <Chip label="Enable" color="primary" size="small" />
    //                         </Typography>
    //                     ) : (
    //                         <Typography>
    //                             <Chip label="Disable" color="primary" size="small" variant="outlined" />
    //                         </Typography>
    //                     ),
    //                     _auto: auto ? (
    //                         <Typography>
    //                             <Chip label="Enable" color="primary" size="small" />
    //                         </Typography>
    //                     ) : (
    //                         <Typography>
    //                             <Chip label="Disable" color="primary" size="small" variant="outlined" />
    //                         </Typography>
    //                     ),
    //                     resendButton: auto ? (
    //                         <Button variant="contained" disabled>
    //                             {t('RESEND')}
    //                         </Button>
    //                     ) : (
    //                         <Button variant="contained" onClick={() => handleClick(data.id)}>
    //                             {t('RESEND')}
    //                         </Button>
    //                     ),
    //                     created_at: data.created_at.substring(0, 10),
    //                     action1: <TableAction id={data.id} onEdit={() => handleEdit(data.id)} />,
    //                     action2: <TableAction id={data.id} onView={() => handleNavigateView(data.id)} />
    //                 });
    //             });

    //             setCampignData(tableDataArr);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    //     setRefresh(!refresh); // Toggle refresh

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //CampaignFilter
    useEffect(() => {
        CampaignFilter(
            filterCompany == 'all' ? 0 : filterCompany,
            filterCountry == 'all' ? 0 : filterCountry,
            filterType == 'all' ? 'all' : filterType
        ).then((result) => {
            console.log('result', result);
            setCampignData([]);

            let tableDataArr = [];

            result.forEach((data) => {
                const isEnabled = data.enable === true;
                const isPushNotifiaction = data.push_notification === true;
                const isSMS = data.sms === true;
                const auto = data.auto === true;

                tableDataArr.push({
                    campaign_name: data.campaign_name || '-',
                    content: data.content || '-',
                    created_by: data.created_by || '-',
                    enable: isEnabled ? (
                        <Typography>
                            <Chip label="Enable" color="primary" size="small" />
                        </Typography>
                    ) : (
                        <Typography>
                            <Chip label="Disable" color="primary" size="small" variant="outlined" />
                        </Typography>
                    ),
                    push_notification: isPushNotifiaction ? (
                        <Typography>
                            <Chip label="Enable" color="primary" size="small" />
                        </Typography>
                    ) : (
                        <Typography>
                            <Chip label="Disable" color="primary" size="small" variant="outlined" />
                        </Typography>
                    ),
                    sms: isSMS ? (
                        <Typography>
                            <Chip label="Enable" color="primary" size="small" />
                        </Typography>
                    ) : (
                        <Typography>
                            <Chip label="Disable" color="primary" size="small" variant="outlined" />
                        </Typography>
                    ),
                    _auto: auto ? (
                        <Typography>
                            <Chip label="Enable" color="primary" size="small" />
                        </Typography>
                    ) : (
                        <Typography>
                            <Chip label="Disable" color="primary" size="small" variant="outlined" />
                        </Typography>
                    ),
                    resendButton: auto ? (
                        <Button variant="contained" disabled>
                            {t('RESEND')}
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={() => handleClick(data.id)}>
                            {t('RESEND')}
                        </Button>
                    ),
                    created_at: data.created_at.substring(0, 10),
                    action1: <TableAction id={data.id} onEdit={() => handleEdit(data.id)} />,
                    action2: <TableAction id={data.id} onView={() => handleNavigateView(data.id)} />
                });
            });
            setCampignData(tableDataArr);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCountry, filterCompany, filterType, updated]);

    //handle company Filter
    const handleCompanyFilter = (e) => {
        setFilterCompany(e.target.value);
    };

    //handle Country Filter
    const handleCountryFilter = (e) => {
        setFilterCountry(e.target.value);
    };

    //handle Type Filter
    const handleTypeFilter = (e) => {
        setFilterType(e.target.value);
    };

    const handlealertPopupClose = () => setAlertPopup({ action: false, message: null, type: null, title: null });
    return (
        <>
            <MainCard title={t('CAMPAIGN')}>
                <Box>
                    <form>
                        {' '}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'left',
                                justifyContent: 'center',
                                height: '15vh',
                                ml: 2
                            }}
                        >
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            width: 700,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h4">
                                            {t('SELECT COUNTRY')}
                                        </Typography>
                                        <TextField
                                            sx={{ mb: 2 }}
                                            select
                                            name="name"
                                            fullWidth
                                            defaultValue={'all'}
                                            placeholder={t('SELECT COUNTRY')}
                                            onChange={(e) => handleCountryFilter(e)}
                                        >
                                            <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                                {t('ALL')}
                                            </MenuItem>
                                            {countries.map((res) => (
                                                <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                                    {res.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            width: 700,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h4">
                                            {t('SELECT COMPANY')}
                                        </Typography>
                                        <TextField
                                            sx={{ mb: 2 }}
                                            select
                                            defaultValue={'all'}
                                            name="name"
                                            fullWidth
                                            placeholder={t('SELECT COMPANY')}
                                            onChange={(e) => handleCompanyFilter(e)}
                                        >
                                            <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                                {t('ALL')}
                                            </MenuItem>
                                            {companies.map((res) => (
                                                <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                                    {res.company_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Item>
                                        <Box sx={{ mt: 5, ml: 18, mr: 18 }}>
                                            <AddButton onClick={() => setShowPopup(true)} />
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={2}>
                                    <Item>
                                        <Box sx={{ mt: 5, ml: 12, mr: 12 }}>
                                            <CSVLink data={CampaignData} headers={headers} filename="Campaign Content List">
                                                <CSVButtons />
                                            </CSVLink>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>

                <Box>
                    <form>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20vh',
                                ml: 2
                            }}
                        >
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h4">
                                            {t('SELECT CAMPAIGN TYPE')}
                                        </Typography>
                                        <TextField
                                            sx={{ mb: 2 }}
                                            select
                                            name="name"
                                            fullWidth
                                            defaultValue={'all'}
                                            placeholder={t('SELECT COUNTRY')}
                                            onChange={(e) => handleTypeFilter(e)}
                                        >
                                            <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                                {t('ALL')}
                                            </MenuItem>
                                            <MenuItem sx={{ color: theme.palette.grey[900] }} value="birthday">
                                                {t('BIRTHDAY CAMPAIGN')}
                                            </MenuItem>
                                            <MenuItem sx={{ color: theme.palette.grey[900] }} value="inactive">
                                                {t('INACTIVE CAMPAIGN')}
                                            </MenuItem>
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={3}>
                                    <Box
                                        sx={{
                                            width: 700,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Box sx={{ mt: 5.5, ml: 20 }}>{/* <AddButton onClick={() => setShowPopup(true)} /> */}</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </MainCard>

            {loading ? (
                <Box
                    sx={{
                        width: '100%',
                        mt: '3%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress sx={{ mr: 5 }} />
                    <Typography sx={{ mb: 2 }} variant="h3">
                        {t('LOADING')}
                    </Typography>
                </Box>
            ) : (
                <MainCard sx={{ justifyContent: 'center', mt: 2 }}>
                    <ReusableTable
                        rows={CampaignData}
                        columns={tableColumns}
                        totalElements={totalElements}
                        limit={pagination.limit}
                        page={pagination.page}
                        onPageChange={handlePageChange}
                        onLimitChange={handleLimitChange}
                    />
                </MainCard>
            )}
            {/* custom popup */}
            <Popup title={t('CREATE CAMPAIGN')} width={800} show={showPopup} onClose={handlePopupClose}>
                <Box sx={{ mb: 1 }}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                error={errors['campaign_name'] ? true : false}
                                required
                                name="campaign_name"
                                variant="filled"
                                label={t('CAMPAIGN NAME')}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        campaign_name: e.target.value
                                    });
                                }}
                            />

                            {errors['campaign_name'] && <Typography color="error">{errors['campaign_name']}</Typography>}
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <TextField
                                error={errors['title'] ? true : false}
                                required
                                name="title"
                                variant="filled"
                                label={t('CAMPAIGN TITLE')}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        title: e.target.value
                                    });
                                }}
                            />

                            {errors['title'] && <Typography color="error">{errors['title']}</Typography>}
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <TextField
                                error={errors['content'] ? true : false}
                                required
                                name="content"
                                variant="filled"
                                label={t('CONTENT')}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        content: e.target.value
                                    });
                                }}
                            />

                            {errors['content'] && <Typography color="error">{errors['content']}</Typography>}
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                {t('SELECT COUNTRY')}
                            </Typography>
                            <TextField
                                sx={{ mb: 0 }}
                                select
                                name="name"
                                fullWidth
                                defaultValue={'all'}
                                placeholder={t('SELECT COUNTRY')}
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        country_id: e.target.value
                                    });
                                }}
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                {countries.map((res) => (
                                    <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                        {res.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                {t('SELECT COMPANY')}
                            </Typography>
                            <TextField
                                sx={{ mb: 0 }}
                                select
                                defaultValue={'all'}
                                name="name"
                                fullWidth
                                placeholder={t('SELECT COMPANY')}
                                onChange={(e) =>
                                    setInputs({
                                        ...inputs,
                                        company_id: e.target.value
                                    })
                                }
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                {companies.map((res) => (
                                    <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                        {res.company_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h5">
                                {t('SELECT CAMPAIGN TYPE')}
                            </Typography>
                            <TextField
                                sx={{ mb: 2 }}
                                select
                                name="name"
                                fullWidth
                                defaultValue={'all'}
                                placeholder={t('SELECT COUNTRY')}
                                onChange={(e) => handleTypeSelection(e)}
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                <MenuItem sx={{ color: theme.palette.grey[900] }} value="birthday">
                                    {t('BIRTHDAY')}
                                </MenuItem>
                                <MenuItem sx={{ color: theme.palette.grey[900] }} value="inactive">
                                    {t('INACTIVE')}{' '}
                                </MenuItem>
                            </TextField>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('ENABLE')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.enable}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        enable: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('PUSH NOTIFICATIONS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.push_notification}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        push_notification: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('SMS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.sms}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        sms: e.target.checked ? true : false
                                                    })
                                                }
                                                defaultValue={false}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('AUTO SMS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.auto}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        auto: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Monthly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.monthly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        monthly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Yearly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.yearly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        yearly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Weekly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.weekly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        weekly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>

                        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="reset" variant="contained" onClick={() => handleClear()} sx={{ py: 2, px: 5, mr: 2, ml: 2 }}>
                                {t('CLEAR')}
                            </Button>

                            <Button type="submit" variant="contained" sx={{ py: 2, px: 5 }} disabled={loading}>
                                {loading ? <CircularProgress color="secondary" /> : `${t('SAVE')}`}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Popup>

            {/* Upadate popup */}
            <Popup title={t('UPDATED A CAMPAIGN')} width={800} show={showUpadatePopup} onClose={handleUpdatePopupClose}>
                <Box sx={{ mb: 1 }}>
                    <form onSubmit={(e) => handleUpdateSubmit(e)}>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                error={errors['campaign_name'] ? true : false}
                                required
                                name="campaign_name"
                                variant="filled"
                                label={t('CAMPAIGN NAME')}
                                value={inputs.campaign_name}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        campaign_name: e.target.value
                                    });
                                }}
                            />

                            {errors['campaign_name'] && <Typography color="error">{errors['campaign_name']}</Typography>}
                        </Box>

                        <Box sx={{ mb: 1 }}>
                            <TextField
                                error={errors['title'] ? true : false}
                                required
                                name="title"
                                variant="filled"
                                label={t('CAMPAIGN TITLE')}
                                value={inputs.title}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        title: e.target.value
                                    });
                                }}
                            />

                            {errors['title'] && <Typography color="error">{errors['title']}</Typography>}
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <TextField
                                error={errors['content'] ? true : false}
                                required
                                name="content"
                                variant="filled"
                                label={t('CONTENT')}
                                value={inputs.content}
                                fullWidth
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        content: e.target.value
                                    });
                                }}
                            />

                            {errors['content'] && <Typography color="error">{errors['content']}</Typography>}
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                {t('SELECT COUNTRY')}
                            </Typography>
                            <TextField
                                sx={{ mb: 0 }}
                                select
                                name="name"
                                fullWidth
                                defaultValue={'all'}
                                placeholder={t('SELECT COUNTRY')}
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        country_id: e.target.value
                                    });
                                }}
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                {countries.map((res) => (
                                    <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                        {res.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                {t('SELECT COMPANY')}
                            </Typography>
                            <TextField
                                sx={{ mb: 0 }}
                                select
                                defaultValue={'all'}
                                name="name"
                                fullWidth
                                placeholder={t('SELECT COMPANY')}
                                onChange={(e) =>
                                    setInputs({
                                        ...inputs,
                                        company_id: e.target.value
                                    })
                                }
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                {companies.map((res) => (
                                    <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                        {res.company_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h5">
                                {t('SELECT CAMPAIGN TYPE')}
                            </Typography>
                            <TextField
                                sx={{ mb: 2 }}
                                select
                                name="name"
                                fullWidth
                                defaultValue={'all'}
                                placeholder={t('SELECT COUNTRY')}
                                onChange={(e) => handleTypeSelection(e)}
                            >
                                <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                    {t('ALL')}
                                </MenuItem>
                                <MenuItem sx={{ color: theme.palette.grey[900] }} value="birthday">
                                    {t('BIRTHDAY')}
                                </MenuItem>
                                <MenuItem sx={{ color: theme.palette.grey[900] }} value="inactive">
                                    {t('INACTIVE')}{' '}
                                </MenuItem>
                            </TextField>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('ENABLE')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.enable}
                                                value={inputs.enable}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        enable: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('PUSH NOTIFICATIONS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.push_notification}
                                                value={inputs.push_notification}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        push_notification: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('SMS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.sms}
                                                value={inputs.sms}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        sms: e.target.checked ? true : false
                                                    })
                                                }
                                                defaultValue={false}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                {t('AUTO SMS')}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.auto}
                                                value={inputs.auto}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        auto: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Monthly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.monthly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        monthly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Yearly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.yearly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        yearly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <Item>
                                            <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h5">
                                                Weekly
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Switch
                                                checked={inputs.weekly}
                                                onChange={(e) =>
                                                    setInputs({
                                                        ...inputs,
                                                        weekly: e.target.checked ? true : false
                                                    })
                                                }
                                            />
                                        </Item>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>

                        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="reset" variant="contained" onClick={() => handleClear()} sx={{ py: 2, px: 5, mr: 2, ml: 2 }}>
                                {t('CLEAR')}
                            </Button>

                            <Button type="submit" variant="contained" sx={{ py: 2, px: 5 }} disabled={loading}>
                                {loading ? <CircularProgress color="secondary" /> : `${t('SAVE')}`}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Popup>

            <Popup title={t('Resend Message')} width={800} show={showResendPopup} onClose={handleResendPopupClose}>
                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1, textAlign: 'left' }} variant="h3">
                        {t('DO YOU WANT TO RESEND THIS CAMPAIGN!!!')}
                    </Typography>
                    <form onSubmit={(e) => handleResendSubmit(e)}>
                        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="reset"
                                variant="contained"
                                onClick={() => handleResendPopupClose()}
                                sx={{ py: 2, px: 5, mr: 2, ml: 2 }}
                            >
                                {t('NO')}
                            </Button>

                            <Button type="submit" variant="contained" sx={{ py: 2, px: 5 }} disabled={loading}>
                                {loading ? <CircularProgress color="secondary" /> : `${t('YES')}`}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Popup>
            <DialogBoxPopup
                title={alertPopup.title}
                width={800}
                show={alertPopup.action}
                onClose={handlealertPopupClose}
                message={alertPopup.message}
                type={alertPopup.type}
            />
            <DialogBoxPopup
                title={navigatePopup.title}
                width={800}
                show={navigatePopup.action}
                onClose={handlenavigatePopupClose}
                message={navigatePopup.message}
                type={navigatePopup.type}
            />
        </>
    );
};

export default SmsCampaign;
