import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const getAllCompanies = () => {
    var bodyFormData = new FormData();

    bodyFormData.append('page', '0');

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/companies-list').then((response) => response);
    return dataPromise;
};
export const addCompanies = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_name', details.company_name);
    bodyFormData.append('company_address', details.company_address);
    bodyFormData.append('owner_name', details.owner_name);
    bodyFormData.append('mobile_number', details.mobile_number);
    bodyFormData.append('country_id', details.country_id);
    bodyFormData.append('erp_company', details.erp_company);
    bodyFormData.append('erp_username', details.erp_username);
    bodyFormData.append('erp_password', details.erp_password);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/companies').then((response) => response);

    return dataPromise;
};
export const upadtedCompanies = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_id', details.id);
    bodyFormData.append('company_name', details.company_name);
    bodyFormData.append('company_address', details.company_address);
    bodyFormData.append('owner_name', details.owner_name);
    bodyFormData.append('mobile_number', details.mobile_number);
    bodyFormData.append('country_id', details.country_id);
    bodyFormData.append('erp_company', details.erp_company);
    bodyFormData.append('erp_username', details.erp_username);
    bodyFormData.append('erp_password', details.erp_password);

    console.log('body', details);
    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/companies').then((response) => response);

    return dataPromise;
};

export const getCompaniesById = (id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/companies-by-id').then((response) => response);

    return dataPromise;
};

export const getCompaniesByName = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/companies-by-name').then((response) => response);
    return dataPromise;
};

export const CompanyFiltering = (country_id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('country_id', country_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/company-filter').then((response) => response);
    return dataPromise;
};
