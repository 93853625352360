/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// material-ui
import {
    Table,
    Paper,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
    Pagination
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReusableTable = ({ rows, columns, totalElements, limit, page, onPageChange, onLimitChange, firstColumnFixed }) => {
    const { t, i18n } = useTranslation();
    const handleChangePage = (event, newPage) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        onPageChange(1);
        onLimitChange(+event.target.value);
    };

    return (
        <Paper sx={{ width: '100%', overflowx: 'scroll', overflowY: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead
                        sx={{
                            boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.25)'
                        }}
                    >
                        <TableRow>
                            {columns &&
                                columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>

                    {rows?.length ? (
                        <TableBody>
                            {rows &&
                                rows?.slice(limit * page - limit, limit * page).map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            {columns.map((column, index) => {
                                                const value = row[column.id];
                                                if (firstColumnFixed) {
                                                    if (index == 0) {
                                                        return (
                                                            <TableCell
                                                                color="#fff"
                                                                sx={{
                                                                    position: '-webkit-sticky',
                                                                    position: 'sticky',
                                                                    background: 'green',
                                                                    left: 0,
                                                                    zIndex: 1
                                                                }}
                                                                key={column.id}
                                                                align={column.align}
                                                            >
                                                                <Typography variant="subtitle1" color="white">
                                                                    {column.format ? column.format(value) : value}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableCell color="black" key={column.id} align={column.align}>
                                                                <Typography variant="subtitle1">
                                                                    {column.format ? column.format(value) : value}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <TableCell color="black" key={column.id} align={column.align}>
                                                            <Typography variant="subtitle1">
                                                                {column.format ? column.format(value) : value}
                                                            </Typography>
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <Typography variant="h3" sx={{ textAlign: 'right', p: '20px' }} color="black">
                                <Typography sx={{ mb: 2, textAlign: 'left' }} variant="h4">
                                    {t('NO VALUES AVAILABLE TO SHOW... LOADING')}
                                </Typography>
                            </Typography>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            <Pagination
                sx={{ backgroundColor: 'green', float: 'left' }}
                count={rows.length < limit ? 1 : Math.round(rows.length / limit + 0.5)}
                page={page}
                // hideNextButton={true}
                // hidePrevButton={true}
                onChange={handleChangePage}
            />
        </Paper>
    );
};

export default ReusableTable;
