import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const addContent = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('title', details.title);
    bodyFormData.append('title_ar', details.title_ar);
    bodyFormData.append('enable', true);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/help').then((response) => response);

    return dataPromise;
};

export const getContent = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/help').then((response) => response);
    return dataPromise;
};

export const UpdateContent = (contentDetails) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', contentDetails.id);
    bodyFormData.append('title', contentDetails.title);
    bodyFormData.append('title_ar', contentDetails.title_ar);
    bodyFormData.append('enable', contentDetails.is_enable);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/help').then((response) => response);
    return dataPromise;
};

export const getContact = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/contact').then((response) => response);
    return dataPromise;
};

export const getContantById = (contactDetails) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', contactDetails.id);
    bodyFormData.append('description', contactDetails.description);
    bodyFormData.append('type', contactDetails.type);
    bodyFormData.append('url', contactDetails.url);
    bodyFormData.append('value', contactDetails.value);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/contact').then((response) => response);

    return dataPromise;
};

export const addGiftCardExpairDate = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('month', details.month);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/gift-card-expire').then((response) => response);

    return dataPromise;
};

export const getGiftCardExpairDate = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/gift-card-expire').then((response) => response);

    return dataPromise;
};

export const addUserSms = (details, is_enable) => {
    let sms = {
        id: details.id,
        title: details.title,
        content: details.content,
        enable: is_enable
    };

    const dataPromise = commonApiTemplateForPost(null, sms, 'v1/new-user-sms').then((response) => response);

    return dataPromise;
};

export const UpdateUserSms = (details) => {
    let sms = {
        id: details.id,
        title: details.title,
        content: details.content,
        enable: details.is_enable
    };

    const dataPromise = commonApiTemplateForPut(null, sms, 'v1/new-user-sms').then((response) => response);

    return dataPromise;
};

export const getUserSms = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/new-user-sms').then((response) => response);

    return dataPromise;
};

export const addUnregUserSms = (details, is_enable) => {
    console.log(details);
    let sms = {
        id: details.id,
        title: details.title,
        content: details.content_newUser,
        enable: is_enable
    };

    const dataPromise = commonApiTemplateForPost(null, sms, 'v1/unreg-user-sms').then((response) => response);

    return dataPromise;
};

export const UpdateUnregUserSms = (details) => {
    let sms = {
        id: details.id,
        title: details.title,
        content: details.content,
        enable: details.is_enable
    };

    const dataPromise = commonApiTemplateForPut(null, sms, 'v1/unreg-user-sms').then((response) => response);

    return dataPromise;
};

export const getUnregUserSms = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/unreg-user-sms').then((response) => response);

    return dataPromise;
};

export const sendBulkSms = (details, userId, company_id, country_id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('header', 'Latafa LLC');
    bodyFormData.append('content', details.content);
    bodyFormData.append('user_id', userId);
    bodyFormData.append('country_id', country_id);
    bodyFormData.append('company_id', company_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/send_bulk_sms').then((response) => response);

    return dataPromise;
};

export const sendInactiveUserSms = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('header', details.header);
    bodyFormData.append('content', details.content);
    bodyFormData.append('month', details.month);
    bodyFormData.append('how_many_month', details.how_many_month);
    bodyFormData.append('user_id', details.user_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/send_sms').then((response) => response);

    return dataPromise;
};

export const UpdateOffer = (offerDetails) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', offerDetails.id);
    bodyFormData.append('title', offerDetails.title);
    bodyFormData.append('title_ar', offerDetails.title_ar);
    bodyFormData.append('enable', offerDetails.is_enable);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/offer-title').then((response) => response);
    return dataPromise;
};

export const PushNOtification = (offerDetails) => {
    var bodyFormData = new FormData();
    console.log(offerDetails);
    bodyFormData.append('user_id', offerDetails.user_id);
    bodyFormData.append('company_id', offerDetails.company_id);
    bodyFormData.append('country_id', offerDetails.country_id);
    bodyFormData.append('title ', offerDetails.title);
    bodyFormData.append('content', offerDetails.content);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/bulk-notification').then((response) => response);
    return dataPromise;
};

export const getCustomerListCountByCompanyAndCountry = (company_id, country_id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_id', company_id);
    bodyFormData.append('country_id', country_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/get-user-count-by-country-company').then((response) => response);
    return dataPromise;
};

export const getSMShistory = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/get-sms-with-mobile').then((response) => response);

    return dataPromise;
};

export const AutoSMSForBirthday = (details, is_enable) => {
    let BirthdaySMS = {
        id: details.id,
        title: details.title,
        content: details.content,
        enable: is_enable
    };

    const dataPromise = commonApiTemplateForPost(null, BirthdaySMS, 'v1/birthday-sms').then((response) => response);

    return dataPromise;
};

export const getBirthdaySMS = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/birthday-sms').then((response) => response);

    return dataPromise;
};

export const UpdateSMSForBirthday = (details) => {
    let BirthdaySMS = {
        id: details.id,
        title: details.title,
        content: details.content,
        enable: details.is_enable
    };

    const dataPromise = commonApiTemplateForPost(null, BirthdaySMS, 'v1/birthday-sms').then((response) => response);

    return dataPromise;
};

export const getBirthdayList = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/birthday-sms-list').then((response) => response);

    return dataPromise;
};

export const getReferralList = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/referral-list').then((response) => response);

    return dataPromise;
};

export const UpdateReferralPoint = (RefferalDetails) => {
    var bodyFormData = new FormData();

    bodyFormData.append('value', RefferalDetails.value);
    bodyFormData.append('enable ', RefferalDetails.enable);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/referral-points').then((response) => response);
    return dataPromise;
};

export const getReferralPointEnableOrDisable = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/referral_point_enable').then((response) => response);

    return dataPromise;
};
