// import axios from 'axios';
// // import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import secureLocalStorage from 'react-secure-storage';
// // import { authActions } from 'store/authSlice';

// const AuthSection = () => {
//     const authState = useSelector((state) => state.auth);
//     return authState;
// };
// export default AuthSection;
// // secureLocalStorage.getItem('temp-token');
// const _baseToken = secureLocalStorage.getItem('temp-token');

// export const commonApiTemplateForPost = async (headerType, body, url) => {
//     var config = {
//         headers: {
//             // 'Content-Type': headerType ? headerType : 'application/x-www-form-urlencoded; charset=UTF-8',
//             Authorization: `Bearer ${secureLocalStorage.getItem('temp-token')}`
//         }
//     };

//     const promise = axios.post(url, body, config);
//     // .catch(() => (window.location.href = 'http://localhost:3000/latafa/pages/login/login3'));
//     const dataPromise = promise.then((response) => response.data);
//     return dataPromise;
// };

// export const commonApiTemplateForGet = async (headerType, body, url) => {
//     var config = {
//         headers: {
//             // 'Content-Type': headerType ? headerType : 'application/x-www-form-urlencoded; charset=UTF-8',
//             Authorization: `Bearer ${secureLocalStorage.getItem('temp-token')}`
//         }
//     };
//     const promise = axios.get(url, config);
//     // .catch(() => (window.location.href = 'http://localhost:3000/latafa/pages/login/login3'));
//     const dataPromise = promise.then((response) => response.data);

//     return dataPromise;
// };
// export const commonApiTemplateForPut = async (headerType, body, url) => {
//     var config = {
//         headers: {
//             // 'Content-Type': headerType ? headerType : 'application/x-www-form-urlencoded; charset=UTF-8',
//             Authorization: `Bearer ${secureLocalStorage.getItem('temp-token')}`
//         }
//     };
//     const promise = axios.put(url, body, config);
//     // .catch(() => (window.location.href = 'http://localhost:3000/latafa/pages/login/login3'));
//     const dataPromise = promise.then((response) => response.data);
//     return dataPromise;
// };

// export const commonApiTemplateForAuth = async (body, url) => {
//     const promise = axios.post(url, body);
//     // .catch(() => (window.location.href = 'http://localhost:3000/latafa/pages/login/login3'));
//     const dataPromise = promise.then((response) => response.data);

//     return dataPromise;
// };

import axios from 'axios';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';

const AuthSection = () => {
    const authState = useSelector((state) => state.auth);
    return authState;
};


export const commonApiTemplateForPost = async (headerType, body, url) => {
    const token = secureLocalStorage.getItem('temp-token');

    var config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    const promise = axios.post(url, body, config);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
};

export const commonApiTemplateForGet = async (headerType, body, url) => {
    const token = secureLocalStorage.getItem('temp-token');

    var config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const promise = axios.get(url, config);
    const dataPromise = promise.then((response) => response.data);

    return dataPromise;
};

export const commonApiTemplateForPut = async (headerType, body, url) => {
    const token = secureLocalStorage.getItem('temp-token');

    var config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const promise = axios.put(url, body, config);
    const dataPromise = promise.then((response) => response.data);
    return dataPromise;
};

export const commonApiTemplateForAuth = async (body, url) => {
    const promise = axios.post(url, body);
    const dataPromise = promise.then((response) => response.data);

    return dataPromise;
};
