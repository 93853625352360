/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// material-ui
import { Box, Stack, Grid, Typography, Button, CircularProgress, TextField, MenuItem } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SearchSection from 'layout/MainLayout/Header/SearchSection';
import AddButton from 'layout/Buttons/AddButtons';
import ReusableTable from 'layout/Table/ReusableTable';

import { useTranslation } from 'react-i18next';
import { getDataByFromTo, getSigninReport, getUnregisterdUsers, SighinSearch } from 'services/customer-service/customerService';
import MUIDatePickers from 'layout/MUIDatePickers';
import { CSVLink } from 'react-csv';
import CSVButtons from 'layout/Buttons/CSVButtons';
import { format } from 'date-fns';
import { Login } from '@mui/icons-material';
import DialogBoxPopup from 'layout/DialogBox';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router';
import { getAllCountries } from 'services/country-service/countryService';
import { getAllCompanies } from 'services/company-service/companyService';
import { getUnregUserSms } from 'services/system-Services/systemServices';
// ==============================|| SAMPLE PAGE ||============================== //

const UnregisterdCustomers = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isGetData, setTsGetData] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [tableRowsFilterd, setTableRowsFilterd] = useState([]);
    const [fromDateEnable, setFromDateEnable] = useState(false);
    const [fromDate, setFromDate] = useState(format(new Date(), 'yyyy/MM/dd'));
    const [toDate, setToDate] = useState(format(new Date(), 'yyyy/MM/dd'));
    const [totalElements, setTotalElements] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    });

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchEnabled, setSearvhEnabled] = useState(false);
    const [loadingNew, setLoadingNew] = useState(false);
    const handlePageChange = (page) => {
        setPagination({ ...pagination, page: page });
    };

    const handleLimitChange = (limit) => {
        setPagination({ ...pagination, limit: limit });
    };
    const [privilages, setPrivilages] = useState({});
    const [viewEnable, SetViewEnable] = useState(false);
    const [acceseUpdated, setAcceseUpdated] = useState(false);
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [filterCountry, setFilterCountry] = useState('all');
    const [filterCompany, setFilterCompany] = useState('all');
    const [searchComapny, setSearchCompany] = useState('');
    const [name, setName] = useState('');

    const [navigatePopup, setnavigatePopup] = useState({
        action: false,
        message: null,
        type: null,
        title: null
    });

    const handlenavigatePopupClose = () => {
        navigate('/dashboard');
    };
    // table columns
    const tableColumns = [
        {
            id: 'id',
            label: `${t('ID')}`,
            minWidth: 20,
            align: 'left'
        },
        {
            id: 'name',
            label: `${t('NAME')}`,
            minWidth: 20,
            align: 'left'
        },

        {
            id: 'email',
            label: `${t('EMAIL')}`,
            minWidth: 20,
            align: 'left'
        },
        {
            id: 'phone',
            label: `${t('PHONE')}`,
            minWidth: 20,
            align: 'left'
        },
        {
            id: 'city',
            label: `${t('CITY')}`,
            minWidth: 20,
            align: 'left'
        },
        {
            id: 'points',
            label: `${t('AVAILABLE POINTS')}`,
            minWidth: 20,
            align: 'left'
        },
        {
            id: 'compound',
            label: `${t('COMPOUND POINTS')}`,
            minWidth: 20,
            align: 'left'
        }
        // {
        //     id: 'branch',
        //     label: `${t('BRANCH')}`,
        //     minWidth: 20,
        //     align: 'left'
        // }
    ];

    const headers = [
        { key: 'id', label: `${t('ID')}` },
        { key: 'name', label: `${t('NAME')}` },
        { key: 'dateTime', label: `${t('DATE')}` },
        { key: 'dateTime1', label: `${t('TIME')}` },
        { key: 'email', label: `${t('EMAIL')}` },
        { key: 'phone', label: `${t('PHONE')}` },
        { key: 'city', label: `${t('CITY')}` }
        // { key: 'branch', label: `${t('BRANCH')}` }
    ];

    useEffect(() => {
        const privilageArray = secureLocalStorage.getItem('privi');
        privilageArray.map((data) => {
            if (data.feature_name == 'Sign In Report') {
                setAcceseUpdated(true);
                setPrivilages(data);
                SetViewEnable(data.is_true);
            }
        });
    }, []);

    //User List
    useEffect(() => {
        getUnregisterdUsers(filterCountry === 'all' ? 0 : filterCountry, filterCompany === 'all' ? 0 : filterCompany)
            .then((result) => {
                console.log(result);
                let tableDataArr = [];

                result.map((data) => {
                    const datetime = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(0, 10) || '-';
                    const datetime1 = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(11, 16) || '-';
                    tableDataArr.push({
                        id: data.id,
                        name: data.name == null ? 0 : data.name,
                        points: data.userDetailsPointStatements ? data.userDetailsPointStatements.points : '-',
                        compound: data.userDetailsPointStatements ? data.userDetailsPointStatements.compound : '-',
                        email: data.email ? data.email : '-',
                        phone: data.phone ? data.phone : '-',
                        city: data.city ? data.city : '-'
                    });
                });
                setTableRows(tableDataArr);
                setLoadingNew(false);
            })

            .catch((error) => {
                setLoadingNew(false);
            });
    }, [filterCountry, filterCompany, loading]);

    useEffect(() => {
        setLoadingNew(true);
        if (isGetData) {
            getDataByFromTo(fromDate, toDate)
                .then((res) => {
                    let tableDataArr = [];
                    res.map((data) => {
                        const datetime = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(0, 10) || '-';
                        const datetime1 = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(11, 16) || '-';
                        tableDataArr.push({
                            id: data.id,
                            name: data.name == null ? 0 : data.name,
                            datatime: datetime,
                            dateTime1: datetime1,
                            email: data.email == null ? '-' : data.email,
                            phone: data.phone == null ? 0 : data.phone,
                            city: data.city == null ? '-' : data.city,
                            branch: data.branch == null ? 0 : data.branch
                        });
                    });

                    setTableRows(tableDataArr);
                    setTsGetData(false);
                    setLoadingNew(false);
                })
                .catch(() => {
                    setLoadingNew(false);
                });
        } else {
            setLoadingNew(false);
        }
    }, [isGetData]);

    //search customer
    // useEffect(() => {
    //     SighinSearch(name, searchComapny).then((res) => {
    //         let tableDataArr = [];
    //         res.map((data) => {
    //             const datetime = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(0, 10) || '-';
    //             const datetime1 = data.userDetailsPointStatements?.signInDetails?.dateTime?.substring(11, 16) || '-';
    //             tableDataArr.push({
    //                 id: data.id,
    //                 name: data.name == null ? 0 : data.name,
    //                 datatime: datetime,
    //                 dateTime1: datetime1,
    //                 email: data.email == null ? 0 : data.email,
    //                 phone: data.phone == null ? 0 : data.phone,
    //                 city: data.city == null ? 0 : data.city,
    //                 branch: data.branch == null ? 0 : data.branch
    //             });
    //         });

    //         setTableRows(tableDataArr);
    //     });
    // }, [name, searchComapny]);

    const onDateFromChange = (e) => {
        var date = new Date(e.$d),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        setFromDateEnable(true);
        setFromDate([date.getFullYear(), mnth, day].join('/'));
        setTsGetData(true);
        // fliterDataByFromTo();
    };

    const onDatetoChange = (e) => {
        var date = new Date(e.$d),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        setFromDateEnable(true);
        setToDate([date.getFullYear(), mnth, day].join('/'));
        setTsGetData(true);
        // fliterDataByFromTo();
    };

    useEffect(() => {
        getAllCountries().then((response) => {
            setCountries(response);
        });
        getAllCompanies().then((response) => {
            setCompanies(response);
        });
    }, []);

    const handleCountryFilter = (event) => {
        setFilterCountry(event.target.value);
    };

    const handleCompanyFilter = (event) => {
        setFilterCompany(event.target.value);
    };

    const searchByName = async (event) => {
        // setSearchCompany(event.target.value);
        // setName(event.target.value);

        let tableDataArr = [];
        console.log(event);
        if (event === ' ') {
            setSearvhEnabled(false);
            setLoading(!loading);
            setTableRowsFilterd([]);
        } else {
            setSearvhEnabled(true);
            var filterResult = await tableRows.find((element) => {
                if (element.name.match(event)) {
                    console.log(element);
                    tableDataArr.push(element);
                } else if (element.phone.match(event)) {
                    console.log(element);
                    tableDataArr.push(element);
                }
            });
            console.log(tableDataArr);
            setTableRowsFilterd(tableDataArr);
        }
    };

    return (
        <>
            <MainCard title={t('CUSTOMER NOT REGISTERD')}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '14vh'
                    }}
                >
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    width: 700,
                                    maxWidth: '100%',
                                    mt: 2.5
                                }}
                            >
                                <SearchSection searchByText={(e) => searchByName(e)} />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ mb: 2, textAlign: 'left', ml: 4, mt: 2 }} variant="h3">
                                Count : {tableRows.length}
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    width: 700,
                                    maxWidth: '100%'
                                }}
                            >
                                <Typography sx={{ mb: 1 }} variant="h6">
                                    <Box sx={{ mt: 3, ml: 2, textAlign: 'center' }}>
                                        <CSVLink data={tableRows} headers={headers} filename="Customer Sign In Report">
                                            <CSVButtons />
                                        </CSVLink>
                                    </Box>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <form>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20vh',
                                ml: 2
                            }}
                        >
                            <Grid container spacing={2} sx={{ mt: 4 }}>
                                <Grid item xs={3}>
                                    <Box
                                        sx={{
                                            width: 700,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Typography sx={{ mb: 2 }} variant="h4">
                                            {t('SELECT COUNTRY')}
                                        </Typography>
                                        <TextField
                                            sx={{ mb: 2 }}
                                            select
                                            name="name"
                                            fullWidth
                                            defaultValue={'all'}
                                            placeholder={t('SELECT COUNTRY')}
                                            onChange={(e) => handleCountryFilter(e)}
                                        >
                                            <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                                {t('ALL')}
                                            </MenuItem>
                                            {countries.map((res) => (
                                                <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                                    {res.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <Box
                                        sx={{
                                            width: 700,
                                            maxWidth: '100%'
                                        }}
                                    >
                                        <Typography sx={{ mb: 2 }} variant="h4">
                                            {t('SELECT COMPANY')}
                                        </Typography>
                                        <TextField
                                            sx={{ mb: 2 }}
                                            select
                                            defaultValue={'all'}
                                            name="name"
                                            fullWidth
                                            placeholder={t('SELECT COMPANY')}
                                            onChange={(e) => handleCompanyFilter(e)}
                                        >
                                            <MenuItem sx={{ color: 'black' }} value={'all'} defaultValue={'all'}>
                                                {t('ALL')}
                                            </MenuItem>
                                            {companies.map((res) => (
                                                <MenuItem sx={{ color: theme.palette.grey[900] }} value={res.id}>
                                                    {res.company_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}></Grid>

                                <Grid item xs={3}>
                                    <Box sx={{ mt: 5.5, ml: 20 }}></Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </MainCard>
            {loadingNew ? (
                <Box
                    sx={{
                        width: '100%',
                        mt: '3%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress sx={{ mr: 5 }} />
                    <Typography sx={{ mb: 2 }} variant="h3">
                        {t('LOADING')}
                    </Typography>
                </Box>
            ) : (
                <MainCard sx={{ mt: 2 }}>
                    <ReusableTable
                        rows={searchEnabled ? tableRowsFilterd : tableRows}
                        columns={tableColumns}
                        totalElements={totalElements}
                        limit={pagination.limit}
                        page={pagination.page}
                        onPageChange={handlePageChange}
                        onLimitChange={handleLimitChange}
                    />
                </MainCard>
            )}

            <DialogBoxPopup
                title={navigatePopup.title}
                width={800}
                show={navigatePopup.action}
                onClose={handlenavigatePopupClose}
                message={navigatePopup.message}
                type={navigatePopup.type}
            />
        </>
    );
};

export default UnregisterdCustomers;
