/* eslint-disable no-unused-vars */
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
// ==============================|| APP ||============================== //
const loader = document.querySelector('.preloader');

const showLoader = () => loader.classList.remove('preloader');
const addClass = () => loader.classList.add('loader-hide');
const App = () => {
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        localStorage.setItem('Nav_Lang', 'en');
        showLoader();
        addClass();
    }, []);
    return (
        <Suspense fallback={<div>Loading</div>}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </Suspense>
    );
};

export default App;
