import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const getAllCountries = () => {
    var bodyFormData = new FormData();

    bodyFormData.append('page', '0');

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/country').then((response) => response);
    return dataPromise;
};
export const addCountries = (details) => {
    let country = {
        name: details.name,
        country_code: details.country_code,
        mobile_code: details.mobile_code,
        currency_code: details.currency_code,
        exchange_rate: details.exchange_rate
    };
    const dataPromise = commonApiTemplateForPost(null, country, 'v1/country').then((response) => response);

    return dataPromise;
};

export const upadtedCountry = (details) => {
    let branch = {
        id: details.id,
        name: details.name,
        country_code: details.country_code,
        mobile_code: details.mobile_code,
        currency_code: details.currency_code,
        exchange_rate: details.exchange_rate
    };

    const dataPromise = commonApiTemplateForPut(null, branch, 'v1/country').then((response) => response);

    return dataPromise;
};

export const getCountryById = (id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/country-by-id').then((response) => response);
    return dataPromise;
};

export const SearchCountry = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/country-search').then((response) => response);
    return dataPromise;
};

export const upadtedCurrencyAndEx = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', details.id);
    bodyFormData.append('currencyCode', details.currencyCode);
    bodyFormData.append('exchangeRate', details.exchangeRate);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/country').then((response) => response);

    return dataPromise;
};
