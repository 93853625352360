import * as React from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

const MUIDatePickers = ({ setDate, enable, fromDate }) => {
    // const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));
    const [value, setValue] = React.useState(dayjs());
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const popperSx: SxProps = {
        '& .MuiPaper-root': {
            // border: '1px solid black',
            padding: 2,
            marginTop: 1,
            backgroundColor: 'rgba(32, 117, 6)'
        },
        '& .MuiCalendarPicker-root': {
            backgroundColor: 'rgba(32, 117, 6)'
        },
        '& .MuiPickersDay-dayWithMargin': {
            color: 'rgb(229,228,226)',
            backgroundColor: 'rgba(32, 117, 6)'
        },
        '& .MuiTabs-root': { backgroundColor: 'rgba(32, 117, 6)' }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
                <Box>
                    <DesktopDatePicker
                        // label="Select Date"
                        inputFormat="MM-DD-YYYY"
                        value={value}
                        disableFuture
                        minDate={fromDate}
                        disabled={enable ? false : true}
                        onChange={(e) => {
                            handleChange(e);

                            setDate(e);
                        }}
                        InputProps={{ sx: { '& .MuiSvgIcon-root': { color: 'blue' } } }}
                        PopperProps={{
                            sx: popperSx
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
            </Stack>
        </LocalizationProvider>
    );
};

// export default function MUIDatePickers(setDate) {
//     const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

//     const handleChange = (newValue) => {
//         setValue(newValue);
//         // setDate(newValue);
//     };

//     const popperSx: SxProps = {
//         '& .MuiPaper-root': {
//             // border: '1px solid black',
//             padding: 2,
//             marginTop: 1,
//             backgroundColor: 'rgba(32, 117, 6)'
//         },
//         '& .MuiCalendarPicker-root': {
//             backgroundColor: 'rgba(32, 117, 6)'
//         },
//         '& .MuiPickersDay-dayWithMargin': {
//             color: 'rgb(229,228,226)',
//             backgroundColor: 'rgba(32, 117, 6)'
//         },
//         '& .MuiTabs-root': { backgroundColor: 'rgba(32, 117, 6)' }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Stack spacing={2}>
//                 <Box>
//                     <DesktopDatePicker
//                         // label="Select Date"
//                         inputFormat="MM/DD/YYYY"
//                         value={value}
//                         onChange={setDate}
//                         InputProps={{ sx: { '& .MuiSvgIcon-root': { color: 'blue' } } }}
//                         PopperProps={{
//                             sx: popperSx
//                         }}
//                         renderInput={(params) => <TextField {...params} />}
//                     />
//                 </Box>
//             </Stack>
//         </LocalizationProvider>
//     );
// }
export default MUIDatePickers;
