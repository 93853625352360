// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase } from '@mui/material';

// assets
import { IconSquarePlus } from '@tabler/icons';

const AddButton = ({ onClick }) => {
    const theme = useTheme();
    return (
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
                variant="rounded"
                onClick={onClick}
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light
                    }
                }}
                color="inherit"
            >
                <IconSquarePlus stroke={2.5} size="1.3rem" />
            </Avatar>
        </ButtonBase>
    );
};

export default AddButton;
