import dashboard from './dashboard';
import pages from './pages';
// import utilities from './utilities';
import other from './other';
import CustomerPage from './CustomerPage';

// ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//     items: [dashboard, pages, utilities, other, CustomerPage]
// };

const menuItems = {
    items: [dashboard, other]
};

export default menuItems;
