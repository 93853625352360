/* eslint-disable no-unused-vars */
import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const getCustomerList = () => {
    var bodyFormData = new FormData();

    bodyFormData.append('from', '0');
    bodyFormData.append('to', '900');
    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/customers-list').then((response) => response);
    return dataPromise;
};

export const getUnregisterdUsers = (filterCountry, filterCompany) => {
    var bodyFormData = new FormData();

    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/unregistered-customer-list').then((response) => response);
    return dataPromise;
};

export const addCustomers = (userData) => {
    var bodyFormData = new FormData();

    // bodyFormData.append('address', address);
    // bodyFormData.append('email', email);
    // bodyFormData.append('phone', phone);
    // bodyFormData.append('city', city);
    let user = {
        address: userData.address,
        email: userData.email,
        phone: userData.contactNumber,
        city: userData.country,
        name: userData.name,
        role: 1
    };

    const dataPromise = commonApiTemplateForPost(null, user, 'authentication/customer-register').then((response) => response);
    return dataPromise;
};

export const updateCustomers = (userData) => {
    var bodyFormData = new FormData();

    bodyFormData.append('address', userData.address);
    bodyFormData.append('email', userData.email);
    bodyFormData.append('phone', userData.contactNumber);
    bodyFormData.append('city', userData.country);
    bodyFormData.append('name', userData.name);
    bodyFormData.append('id', userData.id);

    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/customers-update').then((response) => response);
    return dataPromise;
};

export const getCustomerById = (id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/customers-get').then((response) => response);
    return dataPromise;
};

export const SearchCustomers = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);
    bodyFormData.append('from', 0);
    bodyFormData.append('to', 1000000);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/customer-search').then((response) => response);
    return dataPromise;
};

export const CustomersFilter = (filterCompany, filterBranch) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_id', filterCompany);
    bodyFormData.append('branch_id', filterBranch);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/customer-filter').then((response) => response);
    return dataPromise;
};

export const getSigninReport = (filterCountry, filterCompany, from, to) => {
    var bodyFormData = new FormData();
    bodyFormData.append('from', from);
    bodyFormData.append('to', to);
    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signin-report').then((response) => response);
    return dataPromise;
};

export const getSignUpReport = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/signup-report').then((response) => response);
    return dataPromise;
};

export const SighinSearch = (name, company_id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);
    bodyFormData.append('company_id', company_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signin-report-search').then((response) => response);

    return dataPromise;
};

export const SighUpSearch = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signup-report-search').then((response) => response);

    return dataPromise;
};

export const getDataByFromTo = (from, to, filterCountry, filterCompany) => {
    var bodyFormData = new FormData();

    bodyFormData.append('from', from);
    bodyFormData.append('to', to);
    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);
    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signin-report').then((response) => response);

    return dataPromise;
};

export const getDataByFromToInCustomerList = (from, to, filterCountry, filterCompany) => {
    var bodyFormData = new FormData();
    console.log(from);
    console.log(to);
    console.log(filterCountry);
    console.log(filterCompany);
    bodyFormData.append('from', from);
    bodyFormData.append('to', to);
    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);
    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/get-customers-sign-in-time-list').then((response) => response);

    return dataPromise;
};

export const getDataByFromToSignupReport = (from, to) => {
    var bodyFormData = new FormData();

    bodyFormData.append('from', from);
    bodyFormData.append('to', to);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signup-report').then((response) => response);

    return dataPromise;
};

export const getSigninReportPageBy = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('page', details.page);
    bodyFormData.append('limit', details.limit);
    bodyFormData.append('asc', true);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/signin-report-mod').then((response) => response);

    return dataPromise;
};
export const getPasswordReset = (details) => {
    var bodyFormData = new FormData();

    bodyFormData.append('username', details.email);
    bodyFormData.append('password', details.password);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/password-reset').then((response) => response);

    return dataPromise;
};

export const getCountryrById = (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/get-user-by-country').then((response) => response);
    return dataPromise;
};

export const getCoupanReport = () => {
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, bodyFormData, 'v1/get-all-coupons').then((response) => response);
    return dataPromise;
};

export const CoupanFilter = (filterCompany, filterBranch) => {
    var bodyFormData = new FormData();

    bodyFormData.append('company_id', filterCompany);
    bodyFormData.append('branch_id', filterBranch);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/coupons-filter').then((response) => response);
    return dataPromise;
};

export const GiftCardList = (filterCountry, filterCompany) => {
    var bodyFormData = new FormData();

    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/gift-card-report').then((response) => response);
    return dataPromise;
};
