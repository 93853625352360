import { commonApiTemplateForGet, commonApiTemplateForPost, commonApiTemplateForPut } from 'services/commn-request-funtions/commenRequest';

export const getAllBranches = () => {
    // eslint-disable-next-line no-unused-vars
    var bodyFormData = new FormData();

    const dataPromise = commonApiTemplateForGet(null, null, 'v1/branches').then((response) => response);

    return dataPromise;
};
export const getBrancheById = (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', id);
    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/branches-by-id').then((response) => response);
    
    return dataPromise;
};

export const upadtedBranch = (branchDetails) => {
    var bodyFormData = new FormData();
    bodyFormData.append('id', branchDetails.id);
    bodyFormData.append('branch_name', branchDetails.branch_name);
    bodyFormData.append('company_mapping_id', branchDetails.company_mapping_id);
    bodyFormData.append('lat', branchDetails.lat);
    bodyFormData.append('lng', branchDetails.lng);
    const dataPromise = commonApiTemplateForPut(null, bodyFormData, 'v1/branches').then((response) => response);

    return dataPromise;
};

export const addBranch = (branchDetails) => {
    var bodyFormData = new FormData();

    bodyFormData.append('branch_name', branchDetails.branch_name);
    bodyFormData.append('company_mapping_id', branchDetails.company_mapping_id);
    bodyFormData.append('lat', branchDetails.lat);
    bodyFormData.append('lng', branchDetails.lng);
    bodyFormData.append('warehouse_id', branchDetails.warehouse_id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/branches').then((response) => response);

    return dataPromise;
};

export const getBranchByName = (name) => {
    // let branch = {
    //     branch_name: branchDetails.branch_name,
    //     company_mapping_id: branchDetails.company_mapping_id
    //     // country_id: branchDetails.country_name
    // };
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/branches-by-name').then((response) => response);

    return dataPromise;
};

export const getBranchesByName = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append('name', name);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/branches-by-name').then((response) => response);
    return dataPromise;
};

export const BranchFilter = (filterCountry, filterCompany) => {
    var bodyFormData = new FormData();

    bodyFormData.append('country_id', filterCountry);
    bodyFormData.append('company_id', filterCompany);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/branch-filter').then((response) => response);

    return dataPromise;
};

export const getCountriesById = (id) => {
    var bodyFormData = new FormData();

    bodyFormData.append('id', id);

    const dataPromise = commonApiTemplateForPost(null, bodyFormData, 'v1/countries-by-id').then((response) => response);
    return dataPromise;
};
