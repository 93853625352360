import { createStore, applyMiddleware } from 'redux';
// import reducer from './reducer';
import { PersistConfig } from 'redux-persist';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import authSlice from './authSlice';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import customizationReducer from './customizationReducer';
// ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer);
// const persister = 'Free';

const reducer = combineReducers({
    auth: authSlice.reducer,
    customization: customizationReducer
});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

const persister = persistStore(store, null, () => {
  
});

export { store, persister };
