import colors from 'assets/colors';

const popupStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: colors.white,
    minWidth: 300,
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    pt: 4,
    pb: 4,
    border: 'none'
};

export default popupStyles;
